
































import { defineComponent } from '@vue/composition-api';
import { Video } from '@fc/angie-ui';
import { useVideoMediaEffect } from '~/components/storyblok/MediaBackground/useVideoMediaEffect';
import { IVideoExpectedAttrs } from './types';

export default defineComponent({
  name: 'StoryblokVideo',
  inheritAttrs: false,
  components: {
    Video,
  },
  setup(_, context) {
    const {
      firstFrameImage,
      hasControls,
      hasPlayIcon,
      isOverlay,
      mobileVideoPlayback,
      playsAudio,
      tabletUpVideoPlayback,
      mp4MobileVideoUrl,
      mp4VideoUrl,
      webmMobileVideoUrl,
      webmVideoUrl,
    } = context.attrs as IVideoExpectedAttrs ?? {};

    const {
      imageUrl,
      showVideo,
      videoKey,
      videoPlayback,
    } = useVideoMediaEffect({
      context,
      sources: {
        firstFrameImageUrl: firstFrameImage?.filename,
        mp4MobileVideo: mp4MobileVideoUrl,
        mp4Video: mp4VideoUrl,
        webmMobileVideo: webmMobileVideoUrl,
        webmVideo: webmVideoUrl,
        mobileVideoPlayback,
        tabletUpVideoPlayback,
      },
    });

    return {
      hasControls,
      hasPlayIcon,
      isOverlay,
      playsAudio,
      imageUrl,
      showVideo,
      videoKey,
      videoPlayback,
      mp4MobileVideoUrl,
      mp4VideoUrl,
      webmMobileVideoUrl,
      webmVideoUrl,
    };
  },
});
