var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-background"},[(!_vm.isVideo)?_c('FCImage',_vm._b({style:(_vm.imageStyles),attrs:{"fc-provider":"storyblok","fc-modifiers":{
      isStatic: true,
    },"nuxt-image-props":{
      src: _vm.imageSrc,
      alt: _vm.imageAlt,
      sizes: 'xs:150vw, md:125vw, xl:1750px',
      loading: 'eager',
      preload: true,
      smart: true,
      fit: 'in',
    }}},'FCImage',_vm.pictureResponsiveComponent,false)):_c('div',{staticClass:"media-background__video-wrapper fc-flex"},[_c('div',{staticClass:"media-background__video-container fc-flex md:fc-hidden"},[_c('Video',_vm._b({attrs:{"video-key":_vm.videoKey,"first-frame-image-url":_vm.imageUrl,"video-playback":_vm.videoPlayback,"is-background":_vm.isVideo}},'Video',_vm.videoComponent,false),[_c('client-only',[_c('source',{attrs:{"type":"video/mp4","src":_vm.mp4MobileVideo || _vm.mp4Video}}),_vm._v(" "),_c('source',{attrs:{"type":"video/webm","src":_vm.webmMobileVideo || _vm.webmVideo}})])],1)],1),_vm._v(" "),_c('div',{staticClass:"media-background__video-container fc-hidden md:fc-flex"},[_c('Video',_vm._b({attrs:{"video-key":_vm.videoKey,"first-frame-image-url":_vm.imageUrl,"video-playback":_vm.videoPlayback,"is-background":_vm.isVideo}},'Video',_vm.videoComponent,false),[_c('client-only',[_c('source',{attrs:{"type":"video/mp4","src":_vm.mp4Video}}),_vm._v(" "),_c('source',{attrs:{"type":"video/webm","src":_vm.webmVideo}})])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }