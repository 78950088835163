import { computed, SetupContext } from '@vue/composition-api';
import { isMobileEffect } from '~/effects/matchMedia';
import { getRelativeFileName } from '~/utils/helpers';
import { VideoPlayback } from '@fc/angie-ui/src/components/Video/types';
import { ShowVideoValues } from './types';

interface IStickyOpts {
  context: SetupContext,
  sources: {
    firstFrameImageUrl?: string,
    webmMobileVideo?: string,
    webmVideo?: string,
    mp4MobileVideo?: string,
    mp4Video?: string,
    mobileVideoPlayback?: VideoPlayback,
    tabletUpVideoPlayback?: VideoPlayback,
  }
}

const getFileName = (url?: string) => {
  if (url == null) return undefined;
  if (process.env.NODE_ENV === 'development') return url;
  return getRelativeFileName(url);
};

export const useVideoMediaEffect = (options: IStickyOpts) => {
  const {
    firstFrameImageUrl,
    mp4MobileVideo,
    mp4Video,
    webmMobileVideo,
    webmVideo,
    mobileVideoPlayback,
    tabletUpVideoPlayback,
  } = options?.sources ?? {};
  const isMobile = isMobileEffect();

  const showVideo = computed((): ShowVideoValues => {
    const isSafari = (options.context.root?.$device as any).isSafari;
    const webmTabletUpVideo = !isSafari && !!webmVideo;

    return {
      webmMobile: (isMobile.value && !isSafari && !!webmMobileVideo) || (!webmMobileVideo && webmTabletUpVideo),
      webmTabletUp: !isMobile.value && webmTabletUpVideo,
      safariMobile: (isMobile.value && !!mp4MobileVideo) || (!mp4MobileVideo && !!mp4Video),
      safariTabletUp: !isMobile.value && !!mp4Video,
    };
  });

  return {
    imageUrl: computed(() => getFileName(firstFrameImageUrl)),
    videoPlayback: computed(() => {
      if (isMobile.value) {
        return mobileVideoPlayback;
      }
      return tabletUpVideoPlayback;
    }),
    showVideo,
    videoKey: computed((): string => {
      switch (true) {
      case showVideo.value.webmMobile:
        return webmMobileVideo ?? webmVideo ?? '';
      case showVideo.value.webmTabletUp:
        return webmVideo ?? '';
      case showVideo.value.safariMobile:
        return mp4MobileVideo ?? mp4Video ?? '';
      case showVideo.value.safariTabletUp:
        return mp4Video ?? '';
      default:
        return '';
      }
    }),
  };
};
