
import { defineComponent, computed } from '@vue/composition-api';
import { LinkButton } from '@fc/angie-ui';
import MediaBackground from '~/components/storyblok/MediaBackground/MediaBackground.vue';
// import { useAtfElement } from '~/composables/useAtfElement';

export default defineComponent({
  name: 'ConsoleATF',
  components: {
    MediaBackground,
    LinkButton,
  },
  setup() {
    // @ts-expect-error
    const { $store } = useNuxtApp();
    const isPDPCTAVariant = computed(() => $store.getters.exp_032_isPDPCTAVariant);
    const shopLink = computed(() => {
      if ($store.getters.exp_032_isPDPCTAVariant) {
        return 'shop/products/fightcamp';
      }
      return 'shop';
    });
    // const { atfElement } = useAtfElement();

    return {
      // atfElement,
      isPDPCTAVariant,
      headlineText: 'Stay Motivated. Get Stronger.',
      firstFrameImageUrl: 'https://a.storyblok.com/f/152150/2048x858/269aebbf3b/fc-antm-30-aug-first-frame-001.jpg',
      mp4MobileVideo: '',
      mp4Video: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-oct-compressed-001.mp4',
      webmMobileVideo: '',
      webmVideo: '',
      appleBranchLink: {
        url: 'https://fightcamp.app.link/y3nZxmYIDob',
        linktype: 'url',
        fieldtype: 'multilink',
        cached_url: 'https://fightcamp.app.link/y3nZxmYIDob',
      },
      googleBranchLink: {
        id: '',
        url: 'https://fightcamp.app.link/76ZCQs5IDob',
        linktype: 'url',
      },
      iconDimensions: {
        apple: {
          mobileHeight: '36px',
          tabletUpHeight: '48px',
        },
        google: {
          mobileHeight: '48px',
          tabletUpHeight: '66px',
        },
      },
      shopLink,
    };
  },
});
